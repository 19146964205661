.buttons {
    background-color:rgb(53, 5, 163);
    border: none;
    color: white;
    padding: 8px 20px;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 2px 2px;
    cursor: pointer;
  }

  .button5 {border-radius: 12px;
        padding: 12px;
}


  .button5:hover {
  background-color: #d18411;
  color: white;
}
