.suniti_jewellers{
    background-color: rgb(245, 225, 225);
}

.button {
    background-color:rgb(11, 22, 163);
    border: none;
    color: white;
    padding: 6px 15px;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
  }

  .button4 {border-radius: 12px;}

  .button2:hover {
  background-color: #dd9b0b;
  color: white;
}
