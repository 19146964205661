
.sunitibgcolor{
  
    background-color: rgb(243, 246, 255);
}

.showroomround img {
    border-radius: 35px;;
}

.sunitiimground img{
    border-radius: 40px;
}

.rates{
    background-color: rgb(235, 241, 255);
}
.backimage{
    height:  500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/j5Knz593/images-7-3.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}